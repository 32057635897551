<template>
  <div class="content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="搜索">
              <el-input
                clearable
                placeholder="输入规则名称"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                v-model="Active"
                placeholder="请选择"
                @change="handleSearch"
              >
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addParts"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column label="回访规则名称" prop="Name"></el-table-column>
        <el-table-column label="任务创建人" prop="Person"></el-table-column>
        <el-table-column label="任务创建时间" prop="Time"></el-table-column>
        <el-table-column label="有效性" prop="Active"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="editor(scope.row, scope.$index)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="delete (scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 新增弹窗 -->
    <el-dialog
      title="新增"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="980px"
    >
      <el-tabs v-model="activeName">
        <!-- 基本信息 -->
        <el-tab-pane label="基本信息" name="1">
          <div style="height: 50vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="120px"
                size="small"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="规则名称" prop="InstrumentID">
                      <el-input
                        size="small"
                        clearable
                        v-model="ruleForm.InstrumentID"
                        class="width_220"
                        placeholder="请输入规则名称"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="适用项目" prop="CategoryID">
                      <el-radio :label="true" v-model="ruleForm.CategoryID"
                        >所有项目</el-radio
                      >
                      <el-radio :label="false" v-model="ruleForm.CategoryID"
                        >指定项目</el-radio
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="作废时间" prop="zuofei">
                      任务产生后
                      <el-input
                        size="small"
                        v-model="ruleForm.zuofei"
                        class="w_80"
                      ></el-input>
                      天不处理,系统自动作废
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="创建任务时间" prop="tableData">
                      <el-table :data="ruleForm.tableData">
                        <el-table-column label="消耗项目后多长时间创建任务">
                          <template slot-scope="scope">
                            <el-input
                              v-model="scope.row.time"
                              size="small"
                              clearable
                              class="w_80"
                            ></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="话术">
                          <template slot-scope="scope">
                            <el-select
                              v-model="scope.row.InstrumentID"
                              placeholder="请选择"
                              class="width_220"
                            >
                              <el-option
                                v-for="item in list"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                              ></el-option>
                            </el-select>
                          </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                          <template slot-scope="scope">
                            <el-button
                              size="small"
                              type="danger"
                              @click="deleteTime(scope.row, scope.$index)"
                              >删除</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="Addproject text_center" @click="Addproject">
                        <i class="el-icon-plus"></i> 添加
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="有效性" prop="Active">
                      <el-radio :label="true" v-model="ruleForm.Active"
                        >是</el-radio
                      >
                      <el-radio :label="false" v-model="ruleForm.Active"
                        >否</el-radio
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <!-- 适用项目 -->
        <el-tab-pane label="适用项目" name="2">
          <el-row class="marbm_10">
            <el-col :span="6">
              <el-input
                placeholder="输入关键字查询"
                clearable
                v-model="filterProjectName"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-button type="primary" size="small" @click="addSetDetails"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <el-table
            size="small"
            :data="
              goodsRangeList.filter(
                (data) =>
                  !filterProjectName ||
                  data.Name.toLowerCase().includes(
                    filterProjectName.toLowerCase()
                  )
              )
            "
            max-height="400"
          >
            <el-table-column prop="Name" label="商品名称"></el-table-column>
            <el-table-column prop="TypeName" label="商品类型"></el-table-column>
            <el-table-column prop="Amount" label="数量">
              <template slot-scope="scope">
                <el-input
                  class="width_120"
                  size="small"
                  type="number"
                  min="1"
                  v-model.number="scope.row.Amount"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click="remove(1, scope.row, scope.$index)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 回访负责人 -->
        <el-tab-pane label="回访负责人" name="3">
          <el-row class="marbm_10">
            <el-col :span="6">
              <el-input
                placeholder="输入关键字查询"
                clearable
                v-model="JobName"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-button type="primary" size="small" @click="addJob"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <el-table
            size="small"
            :data="
              JobRangeList.filter(
                (data) =>
                  !filterJobName ||
                  data.Name.toLowerCase().includes(
                    filterJobName.toLowerCase()
                  )
              )
            "
            max-height="400"
          >
            <el-table-column prop="Name" label="职务名称"></el-table-column>
            <el-table-column prop="label" label="职务描述"></el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click="remove(3, scope.row, scope.$index)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加适用项目弹窗 -->
    <el-dialog title="添加适用商品" :visible.sync="dialogScope" width="900px">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-collapse v-model="collapseIndex" accordion>
                <el-collapse-item title="产品" name="1">
                  <el-input
                    v-model="productName"
                    placeholder="请输入关键词进行搜索"
                    clearable
                    size="small"
                  ></el-input>
                  <el-tree
                    ref="treeProduct"
                    :data="ProductList"
                    node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }"
                    :filter-node-method="filterNode"
                    :default-checked-keys="productDefaultCheckedKeys"
                    :default-expanded-keys="productDefaultExpandedKeys"
                  >
                    <div slot-scope="{ data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag
                            class="marlt_5"
                            type="info"
                            size="mini"
                            v-if="data.IsGoods == 0"
                          >
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button
                            type="text"
                            @click="changeAll(1, data)"
                            size="mini"
                            v-if="data.IsGoods == 1"
                            >添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-collapse-item>
                <el-collapse-item title="项目" name="2">
                  <el-input
                    v-model="projectName"
                    placeholder="请输入关键词进行搜索"
                    clearable
                    size="small"
                  ></el-input>
                  <el-tree
                    ref="treeProject"
                    :data="ProjectList"
                    node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }"
                    :filter-node-method="filterNode"
                    :default-checked-keys="projectDefaultCheckedKeys"
                    :default-expanded-keys="projectDefaultExpandedKeys"
                  >
                    <div slot-scope="{ data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag
                            class="marlt_5"
                            type="info"
                            size="mini"
                            v-if="data.IsGoods == 0"
                          >
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button
                            type="text"
                            @click="changeAll(2, data)"
                            size="mini"
                            v-if="data.IsGoods == 1"
                            >添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col :span="16">
              <!--展示全部-->
              <el-table
                size="small"
                max-height="500px"
                :data="packageCardSelectAll"
              >
                <el-table-column prop="Name" label="商品名称"></el-table-column>
                <el-table-column
                  prop="TypeName"
                  label="商品类型"
                ></el-table-column>
                <!-- <el-table-column
                prop="OldPrice"
                label="销售价格"
              ></el-table-column> -->
                <el-table-column label="操作" width="80px">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      size="small"
                      @click="remove(2, scope.row, scope.$index)"
                      >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogScope = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "../../../api/CRM/TaskAssignment/CustomerService";
export default {
  name: "CustomerService",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      JobName:"", //职务关键字
      collapseIndex: "", //折叠面板索引
      productName: "", //适用产品搜索名
      projectName: "", //适用项目搜索名
      ProductList: [], //适用产品列表
      ProjectList: [], //适用项目列表
      productDefaultCheckedKeys: [], //产品回显
      productDefaultExpandedKeys: [],
      projectDefaultCheckedKeys: [], //项目回显
      projectDefaultExpandedKeys: [],
      filterProjectName: "", //适用项目名称过滤
      filterJobName:"",
      goodsRangeList: [], //已选择项目
      JobRangeList:[{Name:"BD总监",label:"市场总监"}],//已选择负责人
      packageCardSelectAll: [],
      dialogScope: false,
      list: [{ ID: "4747", Name: "到店邀约" }],
      dialogVisible: false,
      activeName: "1",
      searchName: "",
      ActiveList: [
        { Name: "全部", Value: null },
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
      ],
      Active: "",
      tableData: [],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        tableData: [{ InstrumentID: "", time: "" }],
      },
      rules: {},
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    //翻页
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.handleSearch();
    },
    //获取数据
    handleSearch() {
      var params = {
        PageNum: this.paginations.page,
      };
    },
    //新增弹窗
    addParts() {
      this.dialogVisible = true;
    },
    // 添加商品创建任务时间
    Addproject() {
      this.ruleForm.tableData.push({ InstrumentID: "", time: "" });
    },
    //添加适用项目
    addSetDetails() {
      var that = this;
      that.projectData();
      that.productData();
      that.packageCardSelectAll = Object.assign([], that.goodsRangeList);
      that.dialogScope = true;
    },
    //添加职务
    addJob(){},
    //设置明细中选择的产品、项目...
    changeAll(index, data) {
      if (this.packageCardSelectAll.some((item) => item.ID == data.ID)) {
        this.$message.warning("该商品已添加!");
        return;
      }
      this.packageCardSelectAll.push({
        PID: data.PID,
        ID: data.ID,
        Amount: data.Price,
        OldPrice: data.Price,
        Price: data.Price,
        GoodsCategoryName: data.GoodsCategoryName,
        TotalPrice: "",
        TypeName: index === 1 ? "产品" : "项目",
        Name: data.Name,
      });
    },
    //删除适用项目
    remove(type, row, indexall) {
      var that = this;
      if (type == 1) {
        that.goodsRangeList.splice(indexall, 1);
      } else if(type == 2) {
        that.packageCardSelectAll.splice(indexall, 1);
      }else{
        console.log("删除职务");
      }
    },
    //确认选择项目
    addProjectSelect() {
      var that = this;
      that.goodsRangeList = Object.assign([], that.packageCardSelectAll);
      that.dialogScope = false;
    },
    //编辑
    editor(row, index) {},
    //删除
    delete(row, index) {},
    //关闭弹窗
    closeDialog() {
      this.ruleForm = {tableData:[]};
      this.goodsRangeList = [];
      this.activeName = "1";
      this.dialogVisible = false;
    },
    // 删除商品创建任务时间
    deleteTime(row, index) {
      this.ruleForm.tableData.splice(index, 1);
    },
    // 表单提交
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createParts();
          } else {
            that.updateParts();
          }
        } else {
          that.activeName = "1";
        }
      });
    },
    // 新增接口
    createParts() {},
    // 编辑接口
    updateParts() {},
    // 获取产品
    productData: function () {
      var that = this;
      let params = {
        productName: that.productName,
      };
      API.savingCardProduct(params).then((res) => {
        if (res.StateCode == 200) {
          that.ProductList = res.Data;
          that.setRecursion(res.Data);
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取项目
    projectData: function () {
      var that = this;
      API.savingCardProject().then((res) => {
        if (res.StateCode == 200) {
          that.ProjectList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsGoods == 0) {
          data[i].PID = "0" + data[i].ID;
        } else {
          data[i].PID = "1" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
  },
  /** 监听数据变化   */
  watch: {
    productName(val) {
      this.$refs.treeProduct.filter(val);
    },
    projectName(val) {
      this.$refs.treeProject.filter(val);
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>
 
<style lang='scss'>
.w_80 {
  width: 80px;
}
.Addproject {
  color: #4860fc;
  // border-bottom: 1px solid #EAEEF5;
  cursor: pointer;
}
.Addproject:hover {
  background-color: #f5f7fa;
}
.el-scrollbar_height {
  height: 100%;
  .header_icon {
    background-color: #f4fbff;
    border: 1px solid #4861fc;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .is-horizontal {
    display: none;
  }
}
</style>
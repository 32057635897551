import * as API from '@/api/index'

export default {
    // 获取产品
    savingCardProduct: params => {
        return API.POST('api/savingCard/product', params)
    },
    // 获取项目
    savingCardProject: params => {
        return API.POST('api/savingCard/project', params)
    },
}